import React from "react";
import Section from "../../components/Section/Section";
import "./Summary.scss";

const Summary = ({ title, description }) => {
  return (
    <Section className="Summary">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h3 className="title">{title}</h3>
          </div>
          <div className="col-lg-8">
            <div className="description Body--small">{description}</div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Summary;
